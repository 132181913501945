import { GasPrice } from "cosmwasm";

export type NonEmptyArray<ElementType> = { readonly 0: ElementType } & readonly ElementType[];

export interface BackendSettings {
  readonly nodeUrls: NonEmptyArray<string>;
  readonly denominations: readonly string[];
  readonly addressPrefix: string;
  readonly gasPrice: GasPrice;
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  readonly keplrChainInfo?: any;
}

// Configuration matches local devnet as defined in
// https://github.com/cosmos/cosmjs/tree/main/scripts/wasmd
const devnetSettings: BackendSettings = {
  nodeUrls: ["https://rpc-testnet.zodiatic.io"],
  denominations: ["urmbo", "rmbo"],
  addressPrefix: "zodiatic",
  gasPrice: GasPrice.fromString("0.000urmbo"),
};

const oysternetSettings: BackendSettings = {
  nodeUrls: ["https://rpc-testnet.zodiatic.io"],
  denominations: ["urmbo"],
  addressPrefix: "zodiatic",
  gasPrice: GasPrice.fromString("0.00urmbo"),
  keplrChainInfo: {
    rpc: "https://rpc-testnet.zodiatic.io",
    rest: "https://rpc-testnet.zodiatic.io",
    chainId: "zodiatic-testnet-1",
    chainName: "Zodiatic Testnet",
    stakeCurrency: {
      coinDenom: "RMBO",
      coinMinimalDenom: "urmbo",
      coinDecimals: 6,
    },
    bip44: {
      coinType: 118,
    },
    bech32Config: {
      bech32PrefixAccAddr: "zodiatic",
      bech32PrefixAccPub: "zodiaticpub",
      bech32PrefixValAddr: "zodiaticvaloper",
      bech32PrefixValPub: "zodiaticvaloperpub",
      bech32PrefixConsAddr: "zodiaticvalcons",
      bech32PrefixConsPub: "zodiaticvalconspub",
    },
    currencies: [
      {
        coinDenom: "RMBO",
        coinMinimalDenom: "urmbo",
        coinDecimals: 6,
      },
    ],
    feeCurrencies: [
      {
        coinDenom: "RMBO",
        coinMinimalDenom: "urmbo",
        coinDecimals: 6,
      },
    ],
    features: ["cosmwasm", "ibc-transfer", "ibc-go"],
  },
};

const knownBackends: Partial<Record<string, BackendSettings>> = {
  devnet: devnetSettings,
  cliffnet: {
    nodeUrls: ["https://rpc-tetnet.zodiatic.io"],
    denominations: ["urmbo", "rmbo"],
    addressPrefix: "wasm",
    gasPrice: GasPrice.fromString("0.000urmbo"),
  },
};

export function getCurrentBackend(): BackendSettings {
  const id = process.env.REACT_APP_BACKEND || "devnet";
  const backend = knownBackends[id];
  if (!backend) {
    throw new Error(`No backend found for the given ID "${id}"`);
  }
  return backend;
}
